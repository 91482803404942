.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.decorationPanel {
  position: relative;
  background-color: rgb(0, 1, 16);
}
.decorationPanel .promoContainer {
  z-index: 100;
  width: 80%;
  max-width: 687px;
  position: absolute;
  top: 190px;
  left: 50%;
  transform: translateX(-50%);
}
.decorationPanel .promoContainer .promoInfo {
  margin-top: 31px;
  max-width: 542px;
  color: rgb(166, 223, 239);
  font-size: 18px;
  text-align: left;
  font-weight: 300;
  line-height: 30px;
  list-style: circle;

}
.promoInfo ul {
  list-style: disc;
}
.decorationPanel .videoContainer {
  width: 100%;
  height: auto;
  min-height: 200px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  filter: none;
}
.decorationPanel .videoContainer .videoBG {
  width: 100%;
}
.DgiPd {
  display: flex;
  flex-flow: wrap;
  box-sizing: content-box;
}
@media (min-width: 768px) {
  .decorationPanel {
    -webkit-box-flex: 1;
    flex-grow: 1;
  }
}


@media (min-width: 576px) {
  .decorationPanel {
    -webkit-box-flex: 1;
    flex-grow: 1;
  }
}

@media (min-width: 0px) {
  .decorationPanel {
    -webkit-box-flex: 1;
    flex-grow: 1;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .hWvjeU {
    display: none;
  }
}
@media (min-width: 0px) and (max-width: 576px) {
  .hWvjeU {
    display: none;
  }
}
.decorationPanel {
   box-sizing: border-box;
 }
.decorationPanel .promoContainer .promoHeader {
  color: rgb(255, 255, 255);
  font-size: 46px;
  text-align: left;
  font-weight: 900;
  line-height: 60px;
}
